





































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import { massSet } from '@/services/massage-service';
import AddRoster from '../massageAdd/creatRoster.vue';
import AddMessage from '../massageAdd/creatMessage.vue';
@Component({
  components: {
    AddRoster,
    AddMessage
  }
})
export default class applicationMassage extends Vue {
  [x: string]: any;
  public form: any = {
    size: 10,
    current: 1,
    sendMsgType: 1
  };
  public title: String = '创建图文分类';
  public dialogFormVisible: Boolean = false;
  private tableData: any[] = [];
  public applications: any[] = [];
  public typeOptions: any[] = [
    { id: '0', name: '成功' },
    { id: '1', name: '失败' },
    { id: '2', name: '发送中' }
  ];
  public datacreateTime: any[] = [];
  private currentRow: any = null;
  public visible: Boolean = false;
  public errorMsg: String = '';
  public loading: Boolean = true;
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(WechatSendMessageGroupService) private WechatSendMessageGroupService!: WechatSendMessageGroupService;
  // 群发消息列表
  @Inject(massSet) private massSet!: massSet;
  public async created(): Promise<void> {
    await this.applicationsList();
    await this.getList();
  }

  public search() {
    this.form.current = 1;
    if (this.datacreateTime && this.datacreateTime.length > 0) {
      this.form.sendTimeStart = this.datacreateTime[0];
      this.form.sendTimeEnd = this.datacreateTime[1];
    } else {
      this.form.sendTimeStart = "";
      this.form.sendTimeEnd = "";
    }
    this.getList()
  }
  public reset() {
    this.form = {
      size: this.form.size,
      current: 1,
    };
    this.datacreateTime = [];
    this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    this.form.pageSize = Number(this.form.size);
    this.form.currentPage = Number(this.form.current);
    this.form.sendMsgType = '1';
    // this.form = {
    //   pageSize: Number(this.form.size),
    //   currentPage: Number(this.form.current),
    //   sendChannelId: this.form.sendChannelId,
    //   sendTimeStart: this.form.sendTimeStart,
    //   sendTimeEnd: this.form.sendTimeEnd,
    //   msgTitle: this.form.msgTitle,
    //   sendResult: this.form.sendResult,
    //   sendMsgType: '1'
    // }

    const res = await this.massSet.getPeopleList(this.form);
    let data: any = res
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
    this.loading = false;
  }
  //处理专栏(这里是应用)id对应的name
  public async formData(res: any) {
    let data = res.records
    this.tableData = data
  }
  //获取专栏(这里是应用)
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
  }

  //删除图文
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.massSet.deleGroup(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //得到子组件的值
  public selectChange(e: any): void {
    this.dialogFormVisible = e
    this.search()
  }
  //新增 创建图文
  public handAdd(): void {
    localStorage.setItem('messageType', '1')
    this.$router.push("/channelMessage");
  }
  //修改
  public handUpdate(row: any): void {
    localStorage.setItem('messageType', JSON.stringify(row))
    this.$router.push("/channelRoster");
  }
  // 创建预览名单
  public handAddList(): void {
    this.$router.push("/channelRoster");
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
  public openMsg(msg: String): void {
    this.errorMsg = msg;
    this.visible = true;
  }
  public exportClick(row: any): void {
    var that = this;
    var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();
        var timeSend = year + '-' + month + '-' + day;
    this.statisticsApi.errorDownload(row.id, "消息发送失败人员名单"+timeSend)
  }
}
